import React, { Component } from "react";
import axios from "axios";
import { Link } from 'react-router-dom'

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      filesObj: []
    };
  }
  componentDidMount() {
    // axios.post("http://52.3.159.205:8000/api/files_consult/files-list").then(
    axios.post(`https://pdffiles.elespectador.com/api/files_consult/files-list`).then(
      result => {
       
        this.setState({
          filesObj: result.data
        });
      },
      error => {
        this.setState({
          error: true
        });
      }
    );
  }
  render() {
    const { error, filesObj } = this.state;
    
    return (
      <div>
        <nav className="navbar navbar-light bg-light ">
          <img src="logo.png" width="300" className="imagen"></img>
        </nav>
        <nav className="nav">
          <Link className="nav-link active" to="/">
            Importar archivo
          </Link>
          <a className="nav-link list-file" href="#">
            Archivos subidos
          </a>
        </nav>
        <div className="data-table">
          
          <table className="table table-bordered table-striped">
          <thead>
              <tr>
                <th className="text-center"></th>
                <th className="text-center">Nombre </th>
                <th className="text-center">Fecha </th>
                <th className="text-center">Codigo embebido</th>
              </tr>
            </thead>
            <tbody>
              {filesObj.map((att, i) => (
                                
                <tr key={i}>
                  <th>{i + 1}</th>
                  <td>{att.name}</td>
                  <td>{att.correct_date}</td>
                  <td>
                 	  &lt;!DOCTYPE HTML&gt;
                    &lt;iframe sandbox="allow-scripts" src="https://docs.google.com/viewer?url={att.url}
                    {`&amp;`}embedded=true" width="100%" height="500" style="border:
                    none;"&gt;&lt;/iframe&gt;
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default List;
