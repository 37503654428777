import React, { Component } from "react";
import axios from "axios";
import { Link } from 'react-router-dom'
import $ from "jquery";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      selectedFiles: null,
      click:true,
    };
  }

  singleFileChangedHandler = event => {
    this.setState({
      selectedFile: event.target.files[0],
     
    });
  };

  changeState(state){
    this.setState({
      click: state
    });

  }

  singleFileUploadHandler = event => {
    const data = new FormData();

    this.changeState(false);
    
    if (this.state.selectedFile) {
      data.append(
        "importFile",
        this.state.selectedFile,
        this.state.selectedFile.name,
      );
      axios
        // .post("http://52.3.159.205:8000/api/import_file/upload-file", data, {
        .post(`https://pdffiles.elespectador.com/api/import_file/upload-file`, data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${data._boundary}`
          }
        })
        .then(response => {
        
          if (200 === response.status) {

         
            if (response.data.error) {

              if ("LIMIT_FILE_SIZE" === response.data.error.code) {
                this.ocShowAlert("Tamaño máximo= 8MB","#f3de9c", 5000);
              } else {
                this.ocShowAlert(response.data.error, "#f3de9c", 5000);

              }
              this.changeState(true);
          
            } else {

              this.changeState(true);

              let fileName = response.data;
            
              this.ocShowAlert(
                "Codigo embebido " +
                  '<iframe src="https://docs.google.com/viewer?url=' +
                  fileName.location +
                  '&embedded=true" width="100%" height="500" style="border: none;"></iframe>',
                "#519ad4",
                15000
              );
             
          
            }
           
          }
          
        })
        .catch(error => {
          // If another error
          this.changeState(true);
          
          this.ocShowAlert(error, "#fb8e8e",5000);
        });
    } else {
 
      this.ocShowAlert("Por favor selecciona un archivo", "#f3de9c",3000);

      this.changeState(true);
    }

  
  };

  ocShowAlert = (message, background, time) => {
    let alertContainer = document.querySelector("#oc-alert-container"),
      alertEl = document.createElement("div"),
      textNode = document.createTextNode(message);
    alertEl.setAttribute("class", "oc-alert-pop-up");
    $(alertEl).css({ background: background, padding: ".75rem 1.25rem" });
    alertEl.appendChild(textNode);
    alertContainer.appendChild(alertEl);
    setTimeout(function() {
      $(alertEl).fadeOut("slow");
      $(alertEl).remove();
    }, time);
  };

  render() {
    return (
      <div>
        <nav className="navbar navbar-light bg-light ">
          <img src="logo.png" width="300" className="imagen"></img>
        </nav>
        <nav className="nav">
          <a className="nav-link active" href="#">
            Importar archivo
          </a>
          <Link className="nav-link" to="/file-list">
            Archivos subidos
          </Link>
        </nav>
        <div className="container">
          <div id="oc-alert-container"></div>

          <div
            className="card border-light mb-3 mt-5"
            style={{ boxShadow: "0 5px 10px 2px rgba(195,192,192,.5)" }}
          >
            <div className="card-header">
              <h3>Subir archivos PDF</h3>
              {/* <p className="text-muted" style={{ marginLeft: '12px' }}>Upload Size: 250px x 250px ( Max 2MB )</p> */}
            </div>
            <div className="card-body">
              <p className="card-text">
                Selecciona el archivo que deseas cargar
              </p>
              <input type="file" onChange={this.singleFileChangedHandler} />
              <div className="mt-5">
              {this.state.click ? <button 
                  className="btn btn-info"
                  onClick={this.singleFileUploadHandler}
                >
                  Subir
                </button> : <img src="spinner.gif" width="50"></img>}
                
                
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
